.form-input {
    width: 100%;
    height: 40px;
    padding: 0.5rem;
    border: 2px solid #ffffff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    margin: 0.1rem;
}
